import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';
import * as TextUtil from '../../Utils/TextUtil';
import {Menu} from '@styled-icons/feather/Menu';
import {message} from 'antd';

export default function Landing(props) {
  const [clearBanner, setClearBanner] = React.useState(false);
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const [values, setValues] = React.useState({
    name: '',
    phone: '',
    email: '',
    content: '',
  });

  function getInputProps(field) {
    return {
      value: values[field],
      onChange: (e) => {
        setValues({
          ...values,
          [field]: e.target.value,
        });
      },
    };
  }

  async function submitContactRequest() {
    if (!values.name || !values.phone || !values.email || !values.content) {
      alert('請填入所有欄位, 方便我們替您安排合適的服務');
    } else if (!TextUtil.checkPhone(values.phone)) {
      alert('您的手機號碼有誤');
    } else if (!TextUtil.checkEmail(values.email)) {
      alert('您的email有誤');
    } else {
      AppActions.setLoading(true);
      try {
        await AppActions.postContactRequest(values);
        message.success('您的需求已送出, 我們會盡快與您聯絡!');
      } catch (ex) {
        console.warn(ex);
        message.warning('Oops! 系統忙碌中, 請稍後再試一次!');
      }
      AppActions.setLoading(false);
    }
  }

  function scrollTo(sectionId, evt) {
    evt.preventDefault();
    const elem = document.getElementById(sectionId);
    if (elem) {
      const top = elem.offsetTop;
      window.scrollTo({top: top - 64, behavior: 'smooth'});
      if (showMobileMenu) {
        setShowMobileMenu(false);
      }
    }
  }

  const navElems = (
    <>
      <div className="links">
        <a href="/#feature" onClick={scrollTo.bind(null, 'feature')}>
          功能介紹
        </a>
        <a href="/#cooperation" onClick={scrollTo.bind(null, 'cooperation')}>
          預期效益
        </a>
        <a href="/#interface" onClick={scrollTo.bind(null, 'interface')}>
          介面介紹
        </a>
        <a href="/#contact" onClick={scrollTo.bind(null, 'contact')}>
          聯絡焙殼
        </a>
      </div>

      <button
        onClick={() => {
          AppActions.navigate('/admin', {loading: 500});
        }}>
        登入
      </button>

      <button
        className="light"
        onClick={() => {
          AppActions.navigate('/store/register/request', {loading: 500});
        }}>
        註冊
      </button>
    </>
  );

  return (
    <Wrapper>
      <Backdrop
        visible={showMobileMenu}
        onClick={() => setShowMobileMenu(false)}
      />

      <MobileMenu visible={showMobileMenu}>{navElems}</MobileMenu>

      <div className="navbar">
        <div className="content">
          <img
            src="/images/landing-navbar-icon.svg"
            alt="landing icon"
            className="logo"
          />

          {navElems}

          <div className="mobile-menu" onClick={() => setShowMobileMenu(true)}>
            <Menu color={'#6e4d29'} size={24} />
          </div>
        </div>
      </div>

      <section className="hero">
        <img
          src="/images/landing-banner.webp"
          alt="banner"
          style={
            clearBanner ? {filter: 'blur(0px)', transform: 'scale(1)'} : {}
          }
          onLoad={() => {
            setClearBanner(true);
          }}
        />

        <h1>BakerShell</h1>
        <div>
          <h2>{'提供餐飲、烘焙行業'}</h2>
          <h2>{'流暢的點餐解決方案'}</h2>
        </div>
        <button className="cta" onClick={scrollTo.bind(null, 'feature')}>
          EXPLORE
        </button>
      </section>

      <section className="feature" id="feature">
        <h2>強大功能・處理訂單更流暢</h2>
        <h4>Features</h4>

        <div className="part-1">
          <img
            className="left"
            src="/images/landing-feature-part-1.svg"
            alt="part-1"
          />

          <img
            className="right"
            src="/images/landing-feature-part-1-b.svg"
            alt="part-1b"
          />

          <div className="content">
            <div className="items">
              <div>
                <img src="/images/landing-feat-part1-1.svg" alt="part1-1" />
              </div>

              <div>
                <img src="/images/landing-feat-part1-2.svg" alt="part1-2" />
              </div>

              <div>
                <img src="/images/landing-feat-part1-3.svg" alt="part1-3" />
              </div>

              <div>
                <img src="/images/landing-feat-part1-4.svg" alt="part1-4" />
              </div>
            </div>
          </div>
        </div>

        <div className="part-2">
          <img
            className="left"
            src="/images/landing-feat-part2-left.svg"
            alt="part-2"
          />

          <div className="content">
            <div className="row reverse">
              <img src="/images/landing-feat-part2-1.webp" alt="feat" />

              <div>
                <div className="number">1</div>
                <h3>預購銷售模組</h3>
                <div className="line" />
                <p>
                  為了搭配您的生產與製作的需求，我們提供預購型的銷售功能，讓你的出貨不再卡卡，行銷與生產一把抓！
                </p>
              </div>
            </div>

            <div className="row">
              <div>
                <div className="number">2</div>
                <h3>加入LINE@立即購</h3>
                <div className="line" />
                <p>
                  免加設主機，免申請網址，只要網站上輕鬆註冊，30分鐘設定教學，讓你的LINE官方帳號，搖身成為最方便的口袋行動電商
                </p>
              </div>

              <img src="/images/landing-feat-part2-2.webp" alt="feat" />
            </div>
          </div>
        </div>

        <div className="part-2">
          <img
            className="right"
            src="/images/landing-feat-part3-mask.svg"
            alt="part-3"
          />

          <div className="content">
            <div className="row reverse">
              <img src="/images/landing-feat-part2-1.webp" alt="feat" />

              <div>
                <div className="number">3</div>
                <h3>訂單自動推播</h3>
                <div className="line" />
                <p>
                  透過LINE@官方帳號進行推播，讓您的訂單通知即時通知客人，大大降低客服詢問的溝通成本
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="part-2">
          <img
            className="bottom-left"
            src="/images/landing-feat-part4-mask.svg"
            alt="part-3"
          />

          <div className="content">
            <div className="row">
              <div>
                <div className="number">4</div>
                <h3>彈性庫存與運費功能</h3>
                <div className="line" />
                <p>
                  支援多種庫存模式，讓您放心銷售您的商品，利潤與庫存掌控零時差
                </p>
              </div>

              <img src="/images/landing-feat-part2-1.webp" alt="feat" />
            </div>
          </div>
        </div>
      </section>

      <section className="cooperation" id="cooperation">
        <h2>預期效益</h2>
        <h4>Benefit</h4>

        <img
          src="/images/landing-cooperation-left.svg"
          alt="mask"
          className="left"
        />
        <img
          src="/images/landing-cooperation-right.svg"
          alt="mask"
          className="right"
        />

        <div className="content">
          <div className="top">
            <div className="item">
              <div className="L">增加</div>
              <div className="XL">2~3倍</div>
              <div className="M">業績成長</div>
            </div>

            <div className="line">
              <div className="dot" />
              <div className="line" />
              <div className="dot" />
            </div>

            <div className="item">
              <div className="L">減少</div>
              <div className="XL">60%</div>
              <div className="M">人工作業</div>
            </div>
          </div>

          <div className="bottom">
            <div className="placeholder" />
          </div>
        </div>
      </section>

      <section className="interface" id="interface">
        <h2>乾淨直覺的使用介面</h2>
        <h4>Interface</h4>

        <div className="content">
          <div className="items">
            <img src="/images/bakershell-ui.png" alt="interface" />
          </div>
        </div>
      </section>

      <section className="description">
        <div className="content">
          <h3>系統等級說明</h3>
          <h4>會員等級</h4>

          <div className="items">
            <div>
              <img src="/images/bakey.png" alt="bakey" />
              <h3>試用版</h3>
              <div>可以建立商店但無法建立訂單</div>
              <div className="line" />
              <ul>
                <li>+免費建立商店</li>
                <li>+商品上架以及各種規格</li>
                <li>+客製化期間庫存管理</li>
                <li className="disabled">-會員加 Line 立即購</li>
                <li className="disabled">-訂單狀態通知不漏接</li>
                <li className="disabled">-後台管理訂單一站搞定</li>
              </ul>
            </div>
            <div>
              <img src="/images/bakey.png" alt="bakey" />
              <h3>正式版</h3>
              <div>具備所有完整功能</div>
              <div className="line" />
              <ul>
                <li>+免費建立商店</li>
                <li>+商品上架以及各種規格</li>
                <li>+客製化期間庫存管理</li>
                <li>+會員加 Line 立即購</li>
                <li>+訂單狀態通知不漏接</li>
                <li>+後台管理訂單一站搞定</li>
              </ul>
            </div>
            <div>
              <img src="/images/bakey.png" alt="bakey" />
              <h3>專屬客製版</h3>
              <div>完整功能＋您的客製專屬功能</div>
              <div className="line" />
              <ul>
                <li>+正式版完整功能</li>
                <li>+您的客製專屬功能</li>
              </ul>
              <div className="contact">
                <div>聯絡電話</div>
                <div>0920691019 何先生</div>
                <div>電子信箱</div>
                <div>sales@bakershell.com.tw</div>
                <div>誠摯為您服務</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact" id="contact">
        <h2>聯絡焙殼</h2>
        <h3>Contact</h3>
        <h4>留下您的資料，我們將儘速與您聯繫</h4>
        <div className="form">
          <input placeholder="您的姓名" {...getInputProps('name')} />
          <input placeholder="手機號碼" {...getInputProps('phone')} />
          <input placeholder="電子信箱" {...getInputProps('email')} />
          <textarea placeholder="詢問內容" {...getInputProps('content')} />
          <button onClick={submitContactRequest}>送出</button>
        </div>
      </section>

      <section className="footer">
        <div className="content">
          <img src="/images/bakey.png" alt="bakey" className="logo" />

          <h2>BakerShell</h2>
          <h3>焙殼</h3>
          <div>contact@bakershell.com</div>
          <div>02-22222222</div>
          <div>週一至週五上午10點至下午7點（例假日休息）</div>
        </div>

        <div className="social">
          <a href="/#">
            <img src="/images/landing-fb.svg" alt="fb" />
          </a>

          <a href="/#">
            <img src="/images/landing-line.svg" alt="fb" />
          </a>

          <a href="/#">
            <img src="/images/landing-ig.svg" alt="fb" />
          </a>
        </div>
      </section>
    </Wrapper>
  );
}

const MAX_CONTENT_WIDTH = 1200;

const Wrapper = styled.div`
  @font-face {
    font-family: Cochin;
    src: url(/fonts/Cochin.ttf);
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.15);
    }

    100% {
      transform: scale(1);
    }
  }

  & > .navbar {
    z-index: 10;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 64px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    background-color: white;

    & > .content {
      max-width: ${MAX_CONTENT_WIDTH}px;
      margin: 0px auto;
      height: 64px;
      display: flex;
      align-items: center;
      padding: 0 20px;

      & > .logo {
        width: 200px;
        height: 48px;
        object-fit: contain;
      }

      & > .links {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > * {
          font-size: 1rem;
          color: #6e4d29;
          margin: 0px 20px;
        }
      }

      & > button {
        margin-left: 6px;
        background-color: #6e4d29;
        border-radius: 32px;
        color: white;
        font-size: 1rem;
        padding: 4px 15px;
        border: none;
        box-shadow: none;
        transition: all 200ms;
        z-index: 1;

        &:hover {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.66);
        }
        &:active {
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.66);
        }

        &.light {
          background-color: #c2b09c;
        }
      }

      & > .mobile-menu {
        padding: 6px;
        cursor: pointer;
        display: none;
      }

      @media (max-width: 800px) {
        & > .links {
          & > * {
            display: none;
          }
        }

        & > button {
          display: none;
        }

        & > .mobile-menu {
          display: block;
        }
      }
    }
  }

  &::before {
    height: 64px;
    content: '';
    display: block;
  }

  & > section.hero {
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
    background-color: #c2b09c;
    position: relative;
    overflow: hidden;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw:
      height: 100%;
      object-fit: cover;
      filter: blur(8px);
      transform: scale(1.33);
      transition: all 2000ms;
      overflow: hidden;
      z-index: 0;
    }

    & > *:not(img) {
      z-index: 1;
    }

    & > *:not(:last-child) {
      margin-bottom: 40px;
    }

    & h1 {
      color: #fff4e8;
      font-size: 4.5rem;
      line-height: 150%;
      font-family: Cochin;
    }

    & h2 {
      color: white;
      font-size: 2rem;
      line-height: 120%;
      width: 500px;
      text-align: center;
    }

    & button.cta {
      background-color: #fff4e8;
      border-radius: 4px;
      color: #6e4d29;
      font-size: 2rem;
      padding: 6px 20px;
      border: none;
      box-shadow: none;
      transition: all 200ms;
      z-index: 1;
      animation: 1.33s heartbeat infinite;

      &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.66);
        animation: 0s heartbeat;
      }
      &:active {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.66);
        animation: 0s heartbeat;
      }
    }
  }

  & > section.feature {
    max-width: 100vw;
    overflow: hidden;
    padding: 100px 20px;
    background-color: #fff4e8;

    & h2,
    h3,
    h4 {
      color: #6e4d29;
      line-height: 120%;
      text-align: center;
    }

    & h2 {
      font-size: 3rem;
    }

    & h3 {
      font-size: 2rem;
      color: #6e4d29;
    }

    & h4 {
      font-size: 1.6rem;
      color: #6e4d29;
    }

    & > .part-1 {
      position: relative;
      margin-bottom: 60px;

      & > img {
        position: absolute;
        object-fit: contain;
        z-index: 0;

        &.left {
          top: 0;
          left: -150px;
          width: 300px;
          height: 150px;
        }

        &.right {
          top: 0;
          right: -20px;
          width: 150px;
          height: 150px;
          overflow: hidden;
        }
      }

      & > .content {
        z-index: 1;
        padding: 20px;
        max-width: ${MAX_CONTENT_WIDTH}px;
        margin: 0 auto;

        & > .items {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          & > * {
            position: relative;
            width: 220px;
            height: 220px;
            border-radius: 8px;
            overflow: hidden;
            margin: 10px;

            & > img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              transition: all 200ms;
              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }
      }
    }

    & > .part-2 {
      position: relative;

      & > img {
        z-index: 0;
        position: absolute;
        object-fit: contain;
        transition: all 200ms;

        &.left {
          top: 0;
          width: 250px;
          height: 500px;
          left: -40px;
        }

        &.right {
          top: 0;
          width: 250px;
          height: 250px;
          right: -20px;
        }

        &.bottom-left {
          bottom: 0;
          left: -20px;
          width: 250px;
          height: 250px;
        }
      }

      &:hover {
        & > img {
          transform: scale(1.5);
          filter: blur(2px);
        }
      }

      & > .content {
        padding: 20px;
        max-width: ${MAX_CONTENT_WIDTH}px;
        margin: 0 auto;

        & > .row {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          @media (max-width: 734px) {
            &.reverse {
              & > img {
                order: 1;
              }
            }
          }

          & > img {
            z-index: 1;
            width: 300px;
            height: 300px;
            margin: 0px 20px 20px 20px;
            transition: all 200ms;
          }

          & > div {
            z-index: 1;
            flex-basis: 300px;
            flex-grow: 1;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            & > .number {
              width: 60px;
              height: 60px;
              background-color: #6e4d29;
              border-radius: 50%;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.8rem;
              font-weight: 500;
              margin-bottom: 20px;
            }

            & > .line {
              height: 2px;
              background-color: #6e4d29;
              align-self: stretch;
              margin: 30px 0;
            }

            & > p {
              line-height: 150%;
              font-size: 1rem;
              text-align: center;
            }
          }

          &:hover {
            & > img {
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }

  & > section.cooperation {
    padding: 100px 20px;
    background-color: #f9f8f5;
    position: relative;

    & > img {
      position: absolute;
      object-fit: contain;
      transition: all 200ms;
      z-index: 0;

      &.left {
        top: 60px;
        left: -40px;
        width: 300px;
        height: 300px;
      }

      &.right {
        top: 250px;
        right: 0px;
        width: 250px;
        height: 250px;
      }

      @media (max-width: 905px) {
        &.right {
          top: 500px;
        }
      }
    }

    &:hover {
      & > img {
        filter: blur(2px);
        transform: scale(1.5);
      }
    }

    & h2,
    h3,
    h4 {
      color: #6e4d29;
      line-height: 120%;
      text-align: center;
    }

    & h2 {
      font-size: 3rem;
    }

    & h3 {
      font-size: 2rem;
      color: #6e4d29;
    }

    & h4 {
      font-size: 1.6rem;
      color: #6e4d29;
    }

    & > .content {
      max-width: ${MAX_CONTENT_WIDTH}px;
      margin: 0 auto;

      & > .top {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        & > .item {
          flex-basis: 400px;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          align-items: center;

          & > * {
            line-height: 133%;
            margin-bottom: 20px;
          }

          & > .L {
            font-size: 2rem;
          }

          & > .XL {
            font-size: 4rem;
            font-weight: 500;
            animation: 1.33s heartbeat infinite;
          }

          & > .M {
            font-size: 1.8rem;
          }
        }

        & > .line {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 20px;

          & > * {
            background-color: #6e4d29;

            &.dot {
              width: 10px;
              height: 10px;
              border-radius: 50%;
            }

            &.line {
              flex: 1;
              width: 1px;
            }
          }
        }

        @media (max-width: 905px) {
          flex-direction: column;

          & > .item {
            flex-basis: unset;
            margin: 20px;
          }

          & > .line {
            flex-direction: row;
            & > .line {
              height: 1px;
            }
          }
        }
      }

      & > .bottom {
        & > .placeholder {
          display: block;
          max-width: 320px;
          margin: 0 auto;
          width: 320px;
          height: 160px;
        }
      }
    }
  }

  & > section.interface {
    padding: 0px 20px 100px 20px;
    background-color: #f9f8f5;
    position: relative;

    & h2,
    h3,
    h4 {
      color: #6e4d29;
      line-height: 120%;
      text-align: center;
    }

    & h2 {
      font-size: 3rem;
    }

    & h3 {
      font-size: 2rem;
      color: #6e4d29;
    }

    & h4 {
      font-size: 1.6rem;
      color: #6e4d29;
    }

    & > .content {
      max-width: ${MAX_CONTENT_WIDTH}px;
      margin: 0 auto;

      & > .items {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 40px;

        & > img {
          width: 100%;
          object-fit: contain;
          margin: 10px;
          transition: all 200ms;
        }
      }
    }
  }

  & > section.description {
    padding: 20px 20px 80px 20px;
    background-color: #f9f8f5;

    & h2,
    h3,
    h4 {
      color: #6e4d29;
      line-height: 120%;
      text-align: center;
    }

    & h2 {
      font-size: 3rem;
    }

    & h3 {
      font-size: 2rem;
      color: #6e4d29;
    }

    & h4 {
      font-size: 1.6rem;
      color: #6e4d29;
    }

    & > .content {
      max-width: ${MAX_CONTENT_WIDTH}px;
      margin: 0 auto;
      padding: 40px 20px;
      border-radius: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      background-color: white;

      & > .items {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 40px;

        & > * {
          width: 300px;
          border: 1px solid #ccc;
          border-radius: 8px;
          padding: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 10px;
          transition: all 200ms;

          &:hover {
            border: 1px solid #6e4d29;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
          }

          & > ul {
            list-style-type: none;
            padding: 0;
            & > li {
              color: green;
              margin-bottom: 5px;
              &.disabled {
                color: #aaa;
              }
            }
          }

          & > .contact {
            display: flex;
            flex-direction: column;
            align-items: center;
            & > *:not(:last-child) {
              margin-bottom: 5px;
            }
          }

          & > .line {
            align-self: stretch;
            height: 1px;
            background-color: #aaa;
            margin: 20px 0;
          }

          & > img {
            width: 60px;
            height: 60px;
            margin-bottom: 20px;
            object-fit: center;
          }
        }
      }
    }
  }

  & > section.contact {
    padding: 100px 40px;
    background-color: #c2b09c;

    & h2,
    h3,
    h4 {
      color: #f9f8f5;
      line-height: 150%;
      text-align: center;
    }

    & h2 {
      font-size: 3rem;
    }

    & h3 {
      font-size: 2rem;
      color: #6e4d29;
    }

    & h4 {
      font-size: 1.6rem;
      color: #6e4d29;
    }

    & > .form {
      max-width: 600px;
      margin: 0 auto;
      padding: 40px 0px;

      & > *:not(:last-child) {
        border-radius: 4px;
        border: 1px solid #ccc;
        padding: 8px 15px;
        width: 100%;
        margin-bottom: 30px;
      }

      & > textarea {
        min-height: 300px;
      }

      & > button {
        width: 100%;
        background-color: #6e4d29;
        border-radius: 4px;
        color: white;
        font-size: 1.33rem;
        padding: 6px 20px;
        border: none;
        box-shadow: none;
        transition: all 200ms;
        z-index: 1;
        &:hover {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.66);
        }
        &:active {
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.66);
        }
      }
    }
  }

  & > section.footer {
    padding: 100px 40px;
    background-color: white;

    & > .content {
      max-width: ${MAX_CONTENT_WIDTH}px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > h2 {
        font-size: 2.4rem;
        line-height: 120%;
        font-family: Cochin;
      }

      & > h3 {
        font-size: 1.7rem;
        line-height: 120%;
      }

      & > * {
        margin-bottom: 20px;
        color: #6e4d29;

        &.logo {
          width: 128px;
          height: 128px;
          object-fit: contain;
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    & > .social {
      display: flex;
      justify-content: center;
      align-items: center;

      & > a {
        margin: 20px 20px 0 20px;

        & > img {
          width: 48px;
          height: 48px;
          object-fit: contain;
        }
      }
    }
  }
`;

const Backdrop = styled.div`
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  transition: all 200ms;
  background-color: rgba(0, 0, 0, 0.5);
`;

const MobileMenu = styled.div`
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  height: 100vh;
  padding: 20px;
  background-color: white;
  transition: all 200ms;
  transform: translateX(${(props) => (props.visible ? '0px' : '-100%')});
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .links {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      font-size: 1rem;
      color: #6e4d29;
      margin: 20px;
    }
  }

  & button {
    width: 120px;
    margin: 20px;
    background-color: #6e4d29;
    border-radius: 32px;
    color: white;
    font-size: 1rem;
    padding: 4px 15px;
    border: none;
    box-shadow: none;
    transition: all 200ms;
    z-index: 1;

    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.66);
    }
    &:active {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.66);
    }

    &.light {
      background-color: #c2b09c;
    }
  }
`;
